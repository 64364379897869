<template src="@/views/CookiePolicies/CookiePolicies-template.vue"></template>
<style src="@/views/CookiePolicies/CookiePolicies.css"></style>

<script>
import Title from '@/components/global/Title.vue';
export default {
    name: 'CookiePolicies',
    components: {
        Title
    }
}
</script>