<template>
    <div class="container pt-4">
        <Title title="Póliticas de cookies" class="mb-4"></Title>

<div class="block text-xiii">
<p class="marg:xiii.sr">Los siguientes términos de la política de cookies (“Política de Cookies”) de Terminal de Santa Marta (Colombia),Que usa la plataforma de <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> para generar tiquetes de  bus. Nosotros y el usurio del Sitio. La presente Política de Cookies surtirá efecto en el momento en que el Usuario Final acceda al Sitio para hacer uso del servicio de reserva aplicable a las reservas de pasajes de bus. Al acceder al Sitio, presentar información a <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> a través del Sitio y registrarse para la facilidad de contratación / reserva provisto en el Sitio, los Usuarios Finales autorizan expresamente a <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> y a sus afiliadas a usar y/o compartir los Datos Objetivo con sus proveedores de servicios autorizados y con otros terceros aplicables de la forma descrita en la Política de Cookies. Si el Usuario Final no acuerda quedar obligado por los términos de esta Política de Cookies, tal como se señala más adelante, el Usuario Final no podrá acceder al Sitio:</p>
<h4 class="marg:xiii.sr">Ámbito de Aplicación:</h4>
<ol class="padd:xv.oe marg:col-xiii marg:xiii.sr">
    <li>El Sitio al que se redireccionara el usuario para comprar el tiquete de viaje podrá utilizar</li>
    <li>1. un pixel contador o de rastreo (como por ejemplo un pixel inteligente) o pixel orientado (“Pixel”) colocado en determinada(s) página(s) del Sitio para recolectar información pertinente del Sitio y para insertar un cookie en el navegador de uno o más Usuarios Finales (el “Cookie”),</li>
    <li>2. el flujo de datos del navegador de uno o más Usuarios Finales hasta el Sitio u otra información no identificable / no personal, del Usuario Final (en lo sucesivo denominada colectivamente los “Datos Objetivo”). Dichos Datos Objetivo podrán incluir, entre otras cosas, (sólo la información no identificable del usuario final es recolectada por el Sitio (utilizando mecanismos de anonimización cuando resulte pertinente, domicilio IP, fecha y hora de la visita al Sitio y las mercancías o compras realizadas por el usuario Final (de resultar aplicable). Los Datos Objetivo se utilizan para proporcionar anuncios relacionados.</li>
    <li><span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> acuerda que los Datos Objetivo solo serán divulgados por <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> para (i) la ejecución de los Servicios por el Sitio (en caso que <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> lo considere apropiado); (ii) cualquier otro propósito comercial de <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span>; y (iii) para el solo propósito de analizar los datos, lo cual será realizado por dichos terceros en nombre y representación de <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> y al recibir una instrucción expresa de <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> en tal sentido, siempre que para estas subsecciones (ii) y (iii) los Datos Objetivo sean usados y divulgados por <span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> solo sobre una base acumulada (en caso que el Sitio lo considere apropiado). Para los efectos de esta Cláusula, por “Acumulada” se entenderá como una manera en la cual a los Datos Objetivo (definidos a continuación) se les combina con los datos de otras campañas de otros anunciantes y se impide su identificación, ya sea directa o indirectamente.</li>
</ol>
    <h5 class="font-bold marg:xii.sr">Enmienda / Modificación:</h5>

    <p class="marg:xiii.sr"><span class="font-bold orange-gentle:tx">terminaldesantamarta.redbus</span> se reserva el derecho de modificar esta Política de Cookies en cualquier momento, con o sin nuevo aviso, subiendo al Sitio la Política de Cookies modificada, y será obligación del Usuario Final mantenerse al tanto de las modificaciones que se implementen en la Política de Cookies del Sitio.
    Limitación de Responsabilidad:</p>

    <p class="marg:xiii.sr"><span class="font-bold orange-gentle:tx">TERMINALDESANTAMARTA.REDBUS</span> NO SERÁ RESPONSABLE POR NINGÚN DAÑO, DE NINGUNA ÍNDOLE, INCLUYENDO, SIN LIMITACIÓN, DAÑOS DIRECTOS, INDIRECTOS, OCASIONALES, PUNITIVOS Y DAÑOS EMERGENTES ORIGINADOS DE O EN RELACIÓN CON LA POLÍTICA DE COOKIES, EL SITIO, EL PROCESAMIENTO DE LA TRANSACCIÓN, LA INCAPACIDAD DE USAR EL SITIO O EL PROCESAMIENTO DE LA TRANSACCIÓN, O AQUELLOS DERIVADOS DE CUALQUIER RESERVA DE PASAJE ADQUIRIDO U OBTENIDO, O DE MENSAJES RECIBIDOS O TRANSACCIONES CELEBRADAS A TRAVÉS DEL SITIO, QUE EXCEDAN EL VALOR DE LA ÚLTIMA TRANSACCIÓN REALIZADA POR EL USUARIO FINAL A TRAVÉS DEL SITIO.</p>

    <h5 class="marg:xiii.sr">Ley Aplicable y Jurisdicción</h5>

    <p class="marg:xiii.sr">Esta Política de Cookies se regirá e interpretará de conformidad con las leyes de Colombia. Los tribunales del Colombia tendrán jurisdicción exclusiva con respecto a cualquier controversia originada de o en relación con la presente Política de Cookies.</p>
    <h5 class="marg:xiii.sr">Disposiciones Generales:</h5>
    <ol class="padd:xv.oe marg:col-xii">
        <li>Las Partes reconocen y aceptan que, para los efectos de esta Política de Cookies, el formato electrónico será considerado un medio aceptable de comunicación.</li>
        <li>Esta política de Cookies constituye el acuerdo total e integral entre las Partes sobre el asunto de las ‘Normas sobre Cookies aplicables al Sitio’ y anulará y reemplazará a cualquier otro entendimiento, compromiso, declaración o acuerdo previo, ya sea escrito o verbal, entre las Partes de esta Política de Cookies.</li>
        <li>Si cualquier disposición de esta Política de Cookies es hallada inválida o inexigible por algún tribunal u organismo administrativo de jurisdicción competente, dicha invalidez o inexigibilidad no afectará(n) a las demás disposiciones de esta Política de Cookies, las mismas que se mantendrán en pleno vigor y efecto.</li>
        <li>En ningún caso, una demora, falla u omisión (total o parcial) en hacer valer, ejercitar o defender cualquier derecho, poder, privilegio, reclamo o recurso conferido por o emanado de esta Política de Cookies, o por ley, se considerará que constituye o se interpretará como una renuncia a ése o a cualquier otro derecho, de manera de prohibir la exigibilidad de ése o cualquier otro derecho, poder, privilegio, reclamo o recurso, en cualquier otra instancia, en cual(es)quier momento(s) posterior(es).</li>
    </ol>
</div>
        
    </div>
</template>